<template>
<Card>
    <template #content>
		<div class="p-m-500">
                <p>Si desea generar cargos en base a un plan de servicio determinado selecciónelo de la lista. Si desea utilizar los planes de servicio asignado a cada inmueble seleccione la opción “Predeterminado”.</p>
                <Divider />        
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-ml-3">            
                    <label for="id_mov_plantilla" class="p-text-bold">Plan de Servicio</label>
                    <Dropdown id="id_mov_plantilla" v-model="id_mov_plantilla" :options="lkpPlanesServicio" optionLabel="plantilla" optionValue="id_mov_plantilla" placeholder="Seleccionar..." />						
                </div>
            </div>
        </div>
    </template>
    <template #footer>        
        <div class="p-d-flex p-jc-between">
            <Button label="Regresar"  @click="prevPage()" icon="pi pi-angle-left"/>
            <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" icon-pos="right"/>
        </div>
    </template>
</Card>
</template>
<script>
// import MdsService from '../../service/mds.service';
import { CURRENT_APP_MODE } from '../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'MdsEmisionPlanServicio',
    components: {
        Divider
    },    
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id: '',
					id_mov_plantilla: '0',
					id_cta_habiente_info: '',
					id_categoria: '',
					tasa_cambio: 7.82,
					fecha_emision: new Date(),
					cambiar_fecha_pago: false,
					fecha_pago: null,
					Param1: '',
					Param2: '',
					Param3: '',
					Param4: '',
					Param5: '',
					Param6: '',							
				}
			}
		}
	},    
    data() {        
        return {
            appMode: CURRENT_APP_MODE,
			id: this.formData.id,
			id_mov_plantilla: this.formData.id_mov_plantilla,
			id_cta_habiente_info: this.formData.id_cta_habiente_info,
			id_categoria: this.formData.id_categoria,
			tasa_cambio: this.formData.tasa_cambio,
			fecha_emision: this.formData.fecha_emision,
			cambiar_fecha_pago: this.formData.cambiar_fecha_pago,
			fecha_pago: this.formData.fecha_pago,
			Param1: this.formData.Param1,
			Param2: this.formData.Param2,
			Param3: this.formData.Param3,
			Param4: this.formData.Param4,
			Param5: this.formData.Param5,
			Param6: this.formData.Param6,		            
			lkpPlanesServicio: [
				{id_mov_plantilla: '0', plantilla: '(Predeterminado)', referencia: ''}				
			],			
			complejo: this.$store.state.auth.currentAppCtx.complejo,
        }
    },
	mounted() {
		Array.prototype.push.apply(this.lkpPlanesServicio, this.$store.state.auth.currentAppCtx.plantillas_mov);
	},
    methods: {
        prevPage() {
            this.$emit('prevPage', {pageIndex: 1});
        },
        nextPage(){
			let formData = {
				id: this.id,
				id_mov_plantilla: this.id_mov_plantilla,
				id_cta_habiente_info: this.id_cta_habiente_info,
				id_categoria: this.id_categoria,
				tasa_cambio: this.tasa_cambio,
				fecha_emision: this.fecha_emision,
				cambiar_fecha_pago: this.cambiar_fecha_pago,
				fecha_pago: this.fecha_pago,
				Param1: this.Param1,
				Param2: this.Param2,
				Param3: this.Param3,
				Param4: this.Param4,
				Param5: this.Param5,
				Param6: this.Param6
			};

            this.$emit('nextPage', {condiciones: formData, pageIndex: 1});
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}
</style>